export default defineNuxtPlugin({
   dependsOn: ['i18n:plugin'],
   enforce: 'pre',
   setup() {
      const { t } = useNuxtApp().$i18n

      if (import.meta.server) {
         updateSiteConfig({
            description: t('global.description'),
            name: t('global.title'),
         })
      }

      const config = useSiteConfig()

      watchEffect(() => {
         config.name = t('global.title')
         config.description = t('global.description')
      })

      useHead({ titleTemplate: chunk => chunk ? `${chunk} | ${config.name}` : config.name })
   },
})
